import React from 'react';

export const AlignLogo = () => (
  <svg
    width="150"
    height="69"
    viewBox="0 0 150 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.5852 0C62.8093 0 60.5646 2.28034 60.5646 5.08381C60.5646 7.88728 62.8093 10.1676 65.5852 10.1676C68.3612 10.1676 70.6192 7.88728 70.6192 5.08381C70.6192 2.28034 68.3612 0 65.5852 0Z"
      fill="white"
    />
    <path
      d="M120.162 16.7504H127.89L128.01 19.9958C128.714 19.0972 131.635 15.758 136.88 15.758C145.366 15.758 150 21.0552 150 30.2951V51.8326H141.979V30.5633C141.979 25.8026 139.098 23.0802 135.061 23.0802C131.024 23.0802 128.169 25.8026 128.169 30.5633V51.8326H120.149V16.7504H120.162ZM52.4655 51.8326H44.4317V0.845367H52.4655V51.846V51.8326ZM69.622 51.8326H61.5882V16.7504H69.622V51.8326ZM27.3814 16.7504H35.3222V51.8326H27.3814L27.2619 48.1581C25.8012 49.6601 21.9237 52.825 16.4926 52.825C7.02461 52.825 0 45.1273 0 34.2915C0 22.8254 7.59561 15.7714 16.4926 15.7714C22.7204 15.7714 26.2261 19.1911 27.2619 20.4517L27.3814 16.7638V16.7504ZM8.20644 34.3049C8.20644 41.0371 12.2964 45.5296 17.9665 45.5296C23.1188 45.5296 27.7532 41.7344 27.7532 34.3049C27.7532 26.8754 23.318 23.0802 17.9665 23.0802C12.0574 23.0802 8.20644 27.841 8.20644 34.3049ZM103.47 34.3049C103.47 26.9827 98.9023 23.0802 93.7102 23.0802C87.4956 23.0802 83.9235 28.1763 83.9235 34.3049C83.9235 40.7688 87.7744 45.5296 93.7102 45.5296C99.1678 45.5296 103.47 41.426 103.47 34.3049ZM111.026 48.3727C111.026 63.4194 102.302 68.4216 92.037 68.4216C85.185 68.4216 81.0552 66.3295 79.6078 65.6456L81.5997 58.672C82.5027 59.1548 86.2606 61.3944 91.9706 61.3944C97.6806 61.3944 103.019 57.5187 103.019 50.5586V48.1313C102.368 48.8152 98.9687 52.825 92.1698 52.825C82.8081 52.825 75.7436 45.2212 75.7436 34.2915C75.7436 23.3618 82.3964 15.7714 92.2096 15.7714C98.9023 15.7714 102.288 19.6069 102.992 20.4517L103.098 16.7638H111.053V48.3861L111.026 48.3727Z"
      fill="white"
    />
  </svg>
);
